/* eslint-disable no-alert */
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import qs from 'qs';
import swal from "sweetalert";
import { FaTrash } from "react-icons/fa";
import "../styles/AdminSettings.css";

function AdminSettings(props) {
	console.log('AdminSettings=', props);
  const [shopList, setShopList] = useState(props.shops);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [newShop, setNewShop] = useState("");
  

  const addShop = (event) => {
    event.preventDefault();

    if (newShop && password && email) {
	  const data = { 'action': 'registerShop', 'shop': newShop, 'password': password, 'email': email, 'ajax': 1 };
	  const options = {
			method: 'POST',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			data: qs.stringify(data),
			url: `${process.env.REACT_APP_BACKEND_URL}`,
	  };
      axios(options)
        .then((response) => {
		  console.log('response=', response);
		  if(response.data.status == false && response.data.exist == 'shop'){
			  swal("Magasin existe!!", "", "error");
		  }
		  else if(response.data.status == false && response.data.exist == 'email'){
			  swal("Email existe!!", "", "error");
		  }
          else if (response.data.exist == false && response.data.status == true) {
            swal("Magasin enregistré avec succès.", "", "success");
            setPassword("");
            setEmail("");
            setNewShop("");
          }
		  else{
			swal("L'enregistrement a échoué.", "", "error");
			setPassword("");
			setEmail("");
			setNewShop("");
		  }
          axios.get(`${process.env.REACT_APP_BACKEND_URL}?action=findAllShop&ajax=1`).then(
            (resp) => {
              setShopList(resp.data);
            }
          );
          return response.data;
        })
        .catch(() => {
          swal("L'enregistrement a échoué.", "", "error");
          setPassword("");
          setEmail("");
          setNewShop("");
        });
    }
  };

  const deleteShop = (id) => {
    // eslint-disable-next-line no-restricted-globals
      axios.get(`${process.env.REACT_APP_BACKEND_URL}?action=deleteShop&ajax=1&id=${id}`).then(
        (response) => {
          axios.get(`${process.env.REACT_APP_BACKEND_URL}?action=findAllShop&ajax=1`).then(
            (resp) => {
              setShopList(resp.data);
            }
          );
          return response.data;
        }
      );
      swal("Magasin supprimé avec succès.", "", "success");
  };

  return (
    <div>
      <Form className="settingsContainer">
        <div className="settingsItem">
          <Card
            style={{
              padding: "25px",
              borderRadius: "15px",
              width: "31.25rem",
              height: "25rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h3 className="settingsTitle">Ajouter un magasin</h3>
            <Form.Group controlId="shopName">
              <Form.Label>Nom magasin: </Form.Label>
              <Form.Control
                type="text"
                placeholder="Nom magasin"
                value={newShop}
                onChange={(e) => setNewShop(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Mot de passe :</Form.Label>
              <Form.Control
                type="password"
                placeholder="Mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Mail :</Form.Label>
              <Form.Control
                type="email"
                placeholder="Mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Button
              type="button"
              style={{
                backgroundColor: "#3D3D3D",
                border: "solid 1px #3D3D3D",
                marginTop: "10px",
              }}
			  onClick={(e) => addShop(e)}
            >
              Enregistrer
            </Button>
          </Card>
        </div>
        <div className="settingsItem">
          <Card
            style={{
              padding: "25px",
              borderRadius: "15px",
              height: "25rem",
              width: "31.25rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h3 className="settingsTitle">Supprimer un magasin</h3>
            <p>Attention ceci est définitif !</p>
            <ul className="shop-list">
              {shopList.map((shop) => (
                <Card className="card-li">
                  <li key={shop.id}>
                    {shop.shop}
                    <button
                      className="delete-shop-button"
                      type="button"
                      onClick={() => {
                        deleteShop(shop.id);
                      }}
                    >
                      <FaTrash />
                    </button>
                  </li>
                </Card>
              ))}
            </ul>
          </Card>
        </div>
      </Form>
    </div>
  );
}

export default AdminSettings;
