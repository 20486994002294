import React, { useContext, useState, useEffect } from "react";
import ManuSwitch from "./ManuSwitch";
import PrixP2BBleu from "../assets/PrixP2BBleu.png";
import PVC from "../assets/pvc.png";
import P2BLogo from "../assets/Logoplus-removebg2.png";
import PrixPlusdDebadCR from '../assets/prixplusddebad_cr.png';
import PrixPlusdDebadCRPromo from '../assets/prixplusddebad_cr_promo.png';
import Coupon from "../assets/coupon.webp";
import CheckGreen from "../assets/checkg.png";
import CheckRed from "../assets/checkr.png";
import raquetteBlockPrice from "../assets/raquetteBlockPrice.png";
import raquetteBlocPV from "../assets/raquetteBlocPV.png";
import "../styles/App.css";
import "../styles/EtiquetteRaquettes.css";

export default function EtiquetteRaquettes({ refEntered, data }) {
    const [nomProduit, setNomProduit] = useState("bigSizeEtiquetteRaquettes");

    return (
        <div className="fontEtiquette EtiquetteRaquettes">
            <div className="conteneurEtiquetteRaquettes">
                <div className="conteneurCaracteristiquesRaquettes">
                    <div className="raquettesCheckGreen">
                        {/* importer logo du fabricant */}
                        {data[refEntered].BHA && (
                            <img src={CheckGreen} alt="pvc" />
                        )}
                    </div>
                    <div className="raquettesBlockTop">
                        <ManuSwitch product={data[refEntered]} />
                    </div>
                    <div className="raquettesBlockCenter">
                        <div className={nomProduit}>
                            {(data[refEntered].name.replace('(Non Cordée)', '').replace('(Cordée)', '').length > 35) ? `${data[refEntered].name.replace('(Non Cordée)', '').replace('(Cordée)', '').substring(0, 35)}...` : data[refEntered].name.replace('(Non Cordée)', '').replace('(Cordée)', '')}
                        </div>
                    </div>
                    <div className="raquettesBlockFooter">
                        <div className="caracteristiquesProduitRaquettes">
							{data[refEntered]?.features_string}
                        </div>
                    </div>
                </div>
                <div className="conteneurPrixRaquettes">
                    <div className="PVCRaquettes">
                        <img src={raquetteBlocPV} alt="LogoP2B" />
                        <div className="PVCRaquettesPrice">
                            {/* importer Prix de Vente Conseillé avec mention préalable "PVC" */}
                            PVC{" "}<span>{parseFloat(data[refEntered].PVC, 10).toFixed(2)} €</span>
                        </div>
                    </div>
                    <div className="logoP2BouPromoRaquettes">
                        {/* importer logo Plus2Bad ou logo Promo */}
                        {data[refEntered].is_promo && !data[refEntered].CC ? (
                             <div className="logoP2BouPromoRaquettesPromo">PROMO</div>
                        ) : (
                            <div className="logoP2BouPromoRaquettesPrixDebad"><span>PRIX</span><img src={P2BLogo} /></div>
                        )}
                    </div>
                    <div className="PVRaquettes">
                        <img src={raquetteBlockPrice} alt="LogoP2B" />
                        {/* importer Prix de Vente sans mention préalable "PV" */}
                        <div className="PVRaquettesPrice">
                            {parseFloat(data[refEntered].PV, 10).toFixed(2)} €
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
