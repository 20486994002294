import React, { useContext, useState, useEffect } from "react";
import ManuSwitch from "./ManuSwitch";
import PrixP2BBleu from "../assets/PrixP2BBleu.png";
import P2BLogo from "../assets/Logoplus-removebg2.png";
import PVC from "../assets/pvc.png";
import PrixPlusdDebadCR from '../assets/prixplusddebad_cr.png';
import PrixPlusdDebadCRPromo from '../assets/prixplusddebad_cr_promo.png';
import Coupon from "../assets/coupon.webp";
import CheckGreen from "../assets/checkg.png";
import CheckRed from "../assets/checkr.png";
import chaussure1 from "../assets/chaussure1.png";
import chaussure2 from "../assets/chaussure2.png";
import "../styles/App.css";
import "../styles/EtiquetteChaussures.css";

export default function EtiquetteChaussures({ refEntered, data }) {
    const [nomProduit, setNomProduit] = useState("bigSizeEtiquetteChaussures");

    return (
        <div className="fontEtiquette EtiquetteChaussures">
            <div className="conteneurEtiquetteChaussures">
                <div className="conteneurCaracteristiquesChaussures">
                    <div className="logoFabricantChaussures">
                        {/* importer logo du fabricant */}
                        <ManuSwitch product={data[refEntered]} />
                    </div>
                    <div className="chaussuresCheckGreen">
                        {data[refEntered].BHA && (
                            <img src={CheckGreen} alt="pvc" />
                        )}
                    </div>
                    <div className={nomProduit}>
                        {/* importer nom du produit */}
                        {data[refEntered].name.replace('(Non Cordée)', '').replace('(Cordée)', '')}
                    </div>
                    <div className="caracteristiquesProduitChaussures">
                        {/* importer genre - pratique */}
						{data[refEntered]?.features_string}
                    </div>
                </div>

                <div className="conteneurPrixChaussures">
                    <div className="PVCChaussures">
                        <img src={chaussure1} alt="LogoP2B" />
                        <div className="PVCChaussuresPrice">
                            {/* importer Prix de Vente Conseillé avec mention préalable "PVC" */}
                            <span className="PVCChaussuresPriceName">PVC</span><span className="PVCChaussuresPriceValue">{parseFloat(data[refEntered].PVC, 10).toFixed(2)} €</span>
                        </div>
                    </div>
                    <div className="logoP2BouPromoChaussures">
                        {/* importer logo Plus2Bad ou logo Promo */}
                        {data[refEntered].is_promo && !data[refEntered].CC ? (
                            <div className="logoP2BouPromoChaussuresPromo">PROMO</div>
                        ) : (
                            <div className="logoP2BouPromoChaussuresPrixDebad"><span>PRIX</span><img src={P2BLogo} /></div>
                        )}
                    </div>
                    <div className="PVChaussures">
                        <img src={chaussure2} alt="LogoP2B" />
                        {/* importer Prix de Vente sans mention préalable "PV" */}
                        <div className="PVChaussuresPrice">
                            {parseFloat(data[refEntered].PV, 10).toFixed(2)} €
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
