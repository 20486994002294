import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Ticketing from "./pages/Ticketing";
import Settings from "./pages/Settings";
import Permalink from "./pages/Permalink";
import PDF from "./pages/PDF";
import Magasins from "./pages/Magasins";
import TicketingDay from "./pages/TicketingDay";
import Marques from "./pages/Marques";
import RequireAuth from './components/Auth';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<Routes>
			<Route path="/pdfshop/:shop" element={<PDF />} exact />
			<Route element={<Login />} path="/" />
			<Route element={<Login />} path="/signin" />
			<Route element={<Login />} path="/login" />
			<Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} exact />
			<Route path="/ticketing" element={<RequireAuth><Ticketing /></RequireAuth>} exact />
			<Route path="/settings" element={<RequireAuth><Settings /></RequireAuth>} exact />
			<Route path="/magasins" element={<RequireAuth><Magasins /></RequireAuth>} exact />
			<Route path="/marques" element={<RequireAuth><Marques /></RequireAuth>} exact />
			<Route path="/tickets/:shop" element={<RequireAuth><Permalink /></RequireAuth>} exact />
			<Route path="/ticketstoday/:shop" element={<RequireAuth><TicketingDay /></RequireAuth>} exact />
			<Route path="/ticketstoday" element={<RequireAuth><TicketingDay /></RequireAuth>} exact />
			<Route path="/tickets" element={<RequireAuth><Permalink /></RequireAuth>} exact />
		</Routes>
	</BrowserRouter>
);

