import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCog, FaPrint, FaSearch, FaCalendarDay, FaStore } from "react-icons/fa";
import axios from "axios";
import { Helmet } from 'react-helmet';
import { getToken, setToken } from "../components/useToken";
import { MdPrecisionManufacturing } from "react-icons/md";
import Navigation from "../components/Navigation";
import logo from "../assets/Logoplus.png";
import "../styles/Home.css";
import LoadingScreen from "../components/LoadingScreen";

export default function Dashboard() {
  const token = getToken();
  const [shop, setShop] = useState('loading');
  const navigate = useNavigate();


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}?action=findCurrentShop&ajax=1&token=${token.token}`).then((response) => {
      console.log('response2=', response);
      setShop(response.data);
    });
  }, []);

  if (shop == 'loading') {
    return <LoadingScreen />
  }

  return (
    <>
	<Helmet>
		<title>Accueil</title>
      </Helmet>
      <Navigation />
      <div className="Logo">
        <img className="Logo2bad-login" src={logo} alt="Logo" />
      </div>
      <div className="home-buttons">
        <button
          className="csshome-buttons"
          type="button"
          onClick={() => navigate("/ticketing")}
        >
          <FaSearch className="svg-icons-home" />
          <br />
          Tickets <br /> Manuelles
        </button>
        <button className="csshome-buttons" type="button" onClick={() => navigate(`/tickets${shop.shop ? '/' + shop.shop : ''}`)}>
          <FaPrint className="svg-icons-home" />
          <br />
          Tickets <br /> du jour
        </button>
        <button className="csshome-buttons" type="button" onClick={() => navigate(`/ticketstoday${shop.shop ? '/' + shop.shop : ''}`)}>
          <FaCalendarDay className="svg-icons-home" />
          <br />
          Tickets <br /> magasin
        </button>
        {shop?.role === "ROLE_ADMIN" && (
          <>
            <button
              className="csshome-buttons"
              type="button"
              onClick={() => navigate("/magasins")}
            >
              <FaStore className="svg-icons-home" />
              <br />
              Gestion des <br />
              magasins
            </button>
            <button
              className="csshome-buttons"
              type="button"
              onClick={() => navigate("/marques")}
            >
              <MdPrecisionManufacturing className="svg-icons-home" />
              <br />
              Gestion des <br />
              marques
            </button>
          </>
        )}
        <button
          className="csshome-buttons"
          type="button"
          onClick={() => navigate("/settings")}
        >
          <FaCog className="svg-icons-home" />
          <br />
          Gestion du <br />
          compte
        </button>
      </div>
    </>
  );
}
