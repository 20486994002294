import Spinner from 'react-bootstrap/Spinner';
import "../styles/Loading.css";

function LoadingScreen() {
  return (
	<div class="dlpro-container-loading">
		<Spinner animation="border" role="status">
			<span className="visually-hidden">Loading...</span>
		</Spinner>
	</div>
  );
}

export default LoadingScreen;