import React, { useState, useContext, useEffect } from "react";
import DataTable from 'react-data-table-component';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';
import axios from "axios";
import qs from 'qs';
import Navigation from "../components/Navigation";
import LoadingScreen from "../components/LoadingScreen";
import logo from "../assets/Logoplus.png";
import { getToken, setToken } from "../components/useToken";
import { FaMinus, FaPlus, FaPen, FaTimes } from "react-icons/fa";
import swal from "sweetalert";

export default function Magasins() {
  const token = getToken();
  const [id, setId] = useState(false);
  const [edit, setEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [shopList, setShopList] = useState('loading');
  const [shop, setShop] = useState('loading');
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [newShop, setNewShop] = useState("");

  const [formFields, setFormFields] = useState([])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleEdit = () => setEdit(true);
  const handleShow2 = (e, row) => {
    setShow(true);
    setEdit(true);
    setId(row.id);
    getShopByID(row.id);
  };
  const getShopByID = (id) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}?action=findShopByID&ajax=1&id=${id}`).then((response) => {
      console.log('rrr=', response.data);
      setEmail(response.data.email);
      setNewShop(response.data.shop);
	  console.log('rrr=', response.data.emails);
	  console.log('rrr=', JSON.parse(response.data.emails));
      //let emails = (emails) ? response.data.emails : [];
	  
      setFormFields(JSON.parse(response.data.emails));
    });
  }

  const deleteShop = (id) => {
    // eslint-disable-next-line no-restricted-globals
    axios.get(`${process.env.REACT_APP_BACKEND_URL}?action=deleteShop&ajax=1&id=${id}`).then(
      (response) => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}?action=findAllShop&ajax=1`).then(
          (resp) => {
            setShopList(resp.data);
          }
        );
        return response.data;
      }
    );
    swal("Magasin supprimé avec succès.", "", "success");

    refreshShops();
  };

  const CustomButtonDelete = ({ row, deleteShop }) => {
    return (
      <Button variant="danger" onClick={(e) => deleteShop(row.id)}><FaTimes /></Button>
    );
  };

  const CustomButtonEdit = ({ row, handleShow }) => {
    setEdit(true);
    return (
      <Button variant="light" onClick={(e) => handleShow2(e, row)}><FaPen /></Button>
    );
  };

  const CustomHtmlRow = ({ emails }) => {
    return emails?.map((mail) => (
      <>
        {mail.email} <br />
      </>
    ));
  };

  const [columns, setColumns] = useState([
    {
      selector: row => row.id,
      name: 'ID'
    },
    {
      name: 'Magasin',
      selector: row => row.shop,
    },
	{
      name: 'Notification adresse mail principale',
      selector: row => row.email,
    },
    {
      name: 'Notification adresse mail secondaire',
      cell: row => <CustomHtmlRow emails={row.emails} />,
      selector: row => row.emails,
    },
    {
      cell: row => <CustomButtonEdit row={row} handleShow={handleShow} />,
      button: true,
    },
    {
      cell: row => <CustomButtonDelete row={row} deleteShop={deleteShop} />,
      button: true,
    },
  ]);

  const customStyles = {
    headRow: {
      style: {
        border: 'none',
      },
    },
    headCells: {
      style: {
        color: '#202124',
        fontSize: '14px',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: 'rgb(230, 244, 244)',
        borderBottomColor: '#FFFFFF',
        borderRadius: '25px',
        outline: '1px solid #FFFFFF',
      },
    },
    pagination: {
      style: {
        border: 'none',
      },
    },
  };

  const refreshShops = (event) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}?action=findAllShop&ajax=1`).then((response) => {
      console.log('response1=', response);
      let rows = [];
      for (var i = 0; i < response.data.length; i++) {
        var emails = JSON.parse(response.data[i].emails);
        var str_emails = '';
        for (var j = 0; j < emails?.length; j++) {
          str_emails += emails[j].email + '<br>';
        }
        rows.push({
          id: response.data[i].id,
          shop: response.data[i].shop,
		  email: response.data[i].email,
          emails: JSON.parse(response.data[i].emails)
        });
      }
      setShopList(rows);
    });
  };

  useEffect(() => {
    if (shop == 'loading') {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}?action=findCurrentShop&ajax=1&token=${token.token}`).then((response) => {
        console.log('response2=', response);
        setShop(response.data);
		
      });
    }
    if (shopList == 'loading') {
      refreshShops();
    }
  }, [shop, shopList]);


  if (shop == 'loading' || shopList == 'loading') {
    return <LoadingScreen />
  }

  const createCustomToolBar = (props) => {
    return (
      <div style={{ margin: '15px' }}>
        {props.components.btnGroup}
        <div className='col-xs-8 col-sm-4 col-md-4 col-lg-2'>
          {props.components.searchPanel}
        </div>
      </div>
    );
  }

  const addShop = () => {

    if (newShop && password && email) {
      const data = {
        'action': 'registerShop',
        'shop': newShop,
        'password': password,
        'email': email,
        'emails': JSON.stringify(formFields),
        'ajax': 1
      };
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
        url: `${process.env.REACT_APP_BACKEND_URL}`,
      };
      axios(options)
        .then((response) => {
          console.log('response=', response);
          if (response.data.status == false && response.data.exist == 'shop') {
            swal("Magasin existe!!", "", "error");
          }
          else if (response.data.status == false && response.data.exist == 'email') {
            swal("Email existe!!", "", "error");
          }
          else if (response.data.exist == false && response.data.status == true) {
            swal("Magasin enregistré avec succès.", "", "success");
            setPassword("");
            setEmail("");
            setNewShop("");
          }
          else {
            swal("L'enregistrement a échoué.", "", "error");
            setPassword("");
            setEmail("");
            setNewShop("");
          }
          refreshShops();
          return response.data;
        })
        .catch(() => {
          swal("L'enregistrement a échoué.", "", "error");
          setPassword("");
          setEmail("");
          setNewShop("");
        });
    }
    handleClose();
  };


  const editShop = () => {

    if (id) {
      const data = {
        'action': 'editShop',
        'id': id,
        'shop': newShop,
        'password': password,
        'email': email,
        'emails': JSON.stringify(formFields),
        'ajax': 1
      };
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
        url: `${process.env.REACT_APP_BACKEND_URL}`,
      };
      axios(options)
        .then((response) => {
          console.log('response=', response);
          if (response.data.status == true) {
            swal("Magasin enregistré avec succès.", "", "success");
            setPassword("");
            setEmail("");
            setNewShop("");
          }
          else {
            swal("L'enregistrement a échoué.", "", "error");
            setPassword("");
            setEmail("");
            setNewShop("");
          }
          refreshShops();
          return response.data;
        })
        .catch(() => {
          swal("L'enregistrement a échoué.", "", "error");
          setPassword("");
          setEmail("");
          setNewShop("");
        });
    }
    handleClose();
  };

  const addFields = (event) => {
    event.preventDefault();
    let object = {
      email: ''
    }

    setFormFields([...formFields, object])
  }

  const removeFields = (event, index) => {
    event.preventDefault();
    let data = [...formFields];
    data.splice(index, 1)
    setFormFields(data)
  }

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index]['email'] = event.target.value;
    setFormFields(data);
  }



  return (
    <>
	<Helmet>
		<title>Gestion des magasins</title>
      </Helmet>
      <Navigation />
      <div className="Logo">
        <img className="Logo2bad-login" src={logo} alt="Logo" />
      </div>
      <div className="container-fluid" style={{ paddingLeft: '90px' }}>
        <Button variant="light" style={{ marginBottom: '20px' }} onClick={handleShow}>Ajouter un magasin</Button>
        <DataTable data={shopList} columns={columns} highlightOnHover pointerOnHover />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{edit ? "Modifier" : "Ajouter"} un magasin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nom magasin:</Form.Label>
              <Form.Control
                type="text"
                value={newShop}
                onChange={(e) => setNewShop(e.target.value)}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email :</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Mot de passe :</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ width: '100%' }}>Email notification : <Button variant="light" style={{ float: 'right' }} onClick={(event) => addFields(event)}><FaPlus /></Button></Form.Label>
              {formFields?.map((form, index) => {
                return (
                  <div key={index} style={{ display: 'flex' }}>
                    <Form.Control
                      type="email"
                      value={form.email}
                      onChange={event => handleFormChange(event, index)}
                      autoFocus
                    />
                    <Button style={{ marginLeft: '10px' }} variant="light" onClick={(event) => removeFields(event, index)}><FaMinus /></Button>
                  </div>
                )
              })}
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={(edit) ? editShop : addShop}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
