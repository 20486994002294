import React, { useContext, useState, useEffect } from "react";
import "../styles/DemiEtiquette.css";
import "../styles/App.css";
import ManuSwitch from "./ManuSwitch";
import P2BLogo from "../assets/Logoplus-removebg2.png";
import PrixP2BBlanc from "../assets/prix_blanc_sur_bleu.png";
import PrixPlusdDebadAD from '../assets/prixplusddebad_ad.png';
import PrixPlusdDebadADPromo from '../assets/prixplusddebad_ad_promo.png';
import Coupon from "../assets/coupon.webp";
import CheckGreen from "../assets/checkg.png";
import CheckRed from "../assets/checkr.png";
import demi1 from "../assets/demi1.png";
import demi2 from "../assets/demi2.png";

export default function DemiEtiquette({ refEntered, data }) {
    const [nomProduit, setNomProduit] = useState("bigSizeDemiEtiquette");

    return (
        <div className="fontEtiquette EtiquetteDemi">
            <div className="conteneurEtiquetteDemi">
                <div className="etiquetteDemiCheckGreen">
                    {data[refEntered].BHA && (
                        <img src={CheckGreen} alt="pvc" />
                    )}
                </div>
                <div className="logoFabricantEtiquetteDemi">
                    {/* importer logo du fabricant */}
                    <ManuSwitch product={data[refEntered]} />
                </div>
                <div className={nomProduit}>
                    {/* importer nom du produit */}
                    {data[refEntered].name.replace('(Non Cordée)', '').replace('(Cordée)', '')}
                </div>
                <div className="PVCDemi">
                    <img src={demi1} alt="LogoP2B" />
                    <div className="PVCDemiPrice">
                        {/* importer Prix de Vente Conseillé avec mention préalable "PVC" */}
                        PVC{" "}{parseFloat(data[refEntered].PVC, 10).toFixed(2)} €
                    </div>
                </div>
                <div className="PVDemi">
                    {data[refEntered].is_promo && !data[refEntered].CC ? (
                        <div className="logoP2BouPromoDemiPromo">PROMO</div>
                    ) : (
                        <div className="logoP2BouPromoDemiPrixDebad"><span>PRIX</span><img src={P2BLogo} /></div>
                    )}
                    <img src={demi2} alt="LogoP2B" />
                    {/* importer Prix de Vente sans mention préalable "PV" */}
                    <div className="PVDemiPrice">
                        {parseFloat(data[refEntered].PV, 10).toFixed(2)} €
                    </div>
                </div>
                <div className="caracteristiquesProduitDemi">
                    {/* importer flexibilite - equilibre - poids */}
                    {data[refEntered]?.features_string}
                </div>
            </div>
        </div>
    );
}
