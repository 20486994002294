import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Helmet } from 'react-helmet';
import Navigation from "../components/Navigation";
import AdminSettings from "../components/AdminSettings";
import UserSettings from "../components/UserSettings";
import LoadingScreen from "../components/LoadingScreen";
import logo from "../assets/Logoplus.png";
import { getToken, setToken } from "../components/useToken";

export default function Settings() {
  const token = getToken();
  const [shopList, setShopList] = useState('loading');
  const [shop, setShop] = useState('loading');
  
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}?action=findAllShop&ajax=1`).then((response) => {
		console.log('response1=', response);
		setShopList(response.data);
    });
	axios.get(`${process.env.REACT_APP_BACKEND_URL}?action=findCurrentShop&ajax=1&token=${token.token}`).then((response) => {
		console.log('response2=', response);
      setShop(response.data);
    });
  }, []);
  
  
  if(shop == 'loading' || shopList == 'loading'){
	  return <LoadingScreen />
  }
  
  

  return (
    <>
	<Helmet>
		<title>Paramètres</title>
      </Helmet>
      <Navigation />
      <div className="Logo">
        <img className="Logo2bad-login" src={logo} alt="Logo" />
      </div>
      <div>
          <UserSettings shop={shop} />
      </div>
    </>
  );
}
