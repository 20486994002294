/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-alert */
import React, { useState, useContext } from "react";
import axios from "axios";
import qs from 'qs';
import swal from "sweetalert";
import Form from "react-bootstrap/Form";
import { Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/UserSettings.css";
import { getToken, setToken } from "../components/useToken";

function UserSettings(props) {
	console.log('UserSettings=', props);
  const token = getToken();
  const [mail, setMail] = useState(props.shop.email);
  const [password, setPassword] = useState();

  const handleChangePassword = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  const onPasswordChange = (event) => {
    event.preventDefault();
	
	const data = {
		  'action': 'editShopPassword', 
		  'id': props.shop.id, 
		  'password': password, 
		  'ajax': 1 };
	  const options = {
			method: 'POST',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			data: qs.stringify(data),
			url: `${process.env.REACT_APP_BACKEND_URL}`,
	  };
	  
	  axios(options)
        .then((response) => {
		  swal("Mot de passe modifié.", "", "success");
        setPassword("");
        })
        .catch(() => {
          swal("Une erreur est survenue.", "", "error");
        });
  };

  const handleChangeMail = (event) => {
    setMail(event.target.value);
  };
  const onMailChange = (event) => {
    event.preventDefault();

	  const data = {
		  'action': 'editShopMail', 
		  'id': props.shop.id, 
		  'email': mail, 
		  'ajax': 1 };
	  const options = {
			method: 'POST',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			data: qs.stringify(data),
			url: `${process.env.REACT_APP_BACKEND_URL}`,
	  };
	  
	  axios(options)
        .then((response) => {
		  swal("Adresse mail modifiée.", "", "success");
        setMail("");
        })
        .catch(() => {
          swal("Une erreur est survenue.", "", "error");
        });
  };

  return (
    <div className="modifGeneral">
      <div className="modifMail">
        <Card
          style={{
            padding: "25px",
            borderRadius: "15px",
            height: "394px",
            width: "31.25rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form
            className="settingsPassword"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h3 className="settingsTitle">Modifier mon adresse mail</h3>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Saisir la nouvelle adresse mail</Form.Label>
              <Form.Control
                type="email"
                placeholder="Nouvelle adresse mail"
                value={mail}
                onChange={handleChangeMail}
              />
            </Form.Group>
            <Button
              type="submit"
              onClick={onMailChange}
              style={{
                backgroundColor: "#3D3D3D",
                border: "solid 1px #3D3D3D",
                marginTop: "10px",
              }}
            >
              Modifier
            </Button>
          </Form>
        </Card>
      </div>
      <div className="modifPassword">
        <Card
          style={{
            padding: "25px",
            borderRadius: "15px",
            height: "394px",
            width: "31.25rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form
            className="settingsPassword"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h3 className="settingsTitle">Modifier mon mot de passe</h3>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Saisir le nouveau mot de passe</Form.Label>
              <Form.Control
                type="password"
                placeholder="Nouveau mot de passe"
                value={password}
                onChange={handleChangePassword}
              />
            </Form.Group>
            <Button
              type="button"
              onClick={onPasswordChange}
              style={{
                backgroundColor: "#3D3D3D",
                border: "solid 1px #3D3D3D",
                marginTop: "10px",
              }}
            >
              Modifier
            </Button>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default UserSettings;
