import React, { useState, useEffect, useRef } from "react";
import { Spinner } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import ReactDOMServer from 'react-dom/server';
import { useNavigate, useParams } from "react-router-dom";
import { FaHome, FaPrint } from "react-icons/fa";
import html2canvas from 'html2canvas';
import { Helmet } from 'react-helmet';
import { jsPDF } from "jspdf";
import axios from "axios";
import DemiEtiquette from "../components/DemiEtiquette";
import EtiquetteAccessoires from "../components/EtiquetteAccessoires";
import EtiquetteChaussures from "../components/EtiquetteChaussures";
import EtiquetteRaquettes from "../components/EtiquetteRaquettes";
import LoadingScreen from "../components/LoadingScreen";
import Navigation from "../components/Navigation";
import logo from "../assets/Logoplus.png";
import "../styles/Navigation.css";
import "../styles/Permalink.css";
import "../styles/Ticketing.css";

export const ComponentToPrint = React.forwardRef((props, ref) => {
	const getPageMargins = () => {
	  return `@page { margin: 2cm !important; }`;
	};
	console.log(props.products);
	return (
	<><style>{getPageMargins()}</style>
		<div className="container-fluid no-padding-print" ref={ref}>
			<div className="row">
				<div className="Logo">
					<img className="Logo2bad-login no-print" src={props.logo} alt="Logo" />
				</div>
				<div className="btnDailyContainer no-print"></div>
				<div className="etiquetteDailyMailing etiquetteDailyMailing"  >
					{props.products && props.getEtiquette(props.products)}
				</div>
				{!props.products.length && (
					<div class="container text-center">
						Aucun produit disponible pour le moment
					</div>
				)}
			</div>
		</div></>
	);
});

export default function Permalink() {
	const componentRef = useRef();
	const { shop } = useParams();
	const navigate = useNavigate();
	const [products, setProducts] = useState({});
	const printRef = React.useRef();
	const [isLoaded, setIsLoaded] = useState(false);

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const handleDownloadPdf = async () => {
		const element = printRef.current;
		const canvas = await html2canvas(element);
		const data = canvas.toDataURL('image/png');

		var pageData = canvas.toDataURL('image/jpeg', 1.0);
		let width = 210;
		let height = canvas.height;
		let widthRatio = width / canvas.width;
		let heightRatio = height / canvas.height;
		let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
		var pdf = new jsPDF('p', 'mm', [width, canvas.height * ratio]);
		pdf.addImage(pageData, 'JPEG', 0, 0, width, canvas.height * ratio);
		pdf.save('etiquettes.pdf');
	};
	useEffect(() => {
		if (!isLoaded) {
			axios
				.get(`${process.env.REACT_APP_BACKEND_URL}?action=modifiedProducts&ajax=1&shop=${shop ? shop : ''}`)
				.then(({ data }) => setProducts(data))
				.finally(() => setIsLoaded(true));
		}
	}, [isLoaded]);

	const getEtiquette = (products) => {
		let DataA = [];
		let DataS = [];
		let DataT = [];
		let DataC = [];
		let DataR = [];
		let DataD = [];
		let j = 1, k = 1, l = 1, m = 1;
		let row = 1, row2 = 1, row3 = 1, row4 = 1;
		for (let i = 0; i < products.length; i++) {
			let product = products[i];
			let data_product = {};
			data_product[product.reference] = product;
			if (product.type == 'A') {
				if (k == 1) {
					DataA[row2] = [];
				}
				DataA[row2].push(<td className="EtiquetteAccessoiresSize" width="7cm" height="3cm"><EtiquetteAccessoires refEntered={product.reference} data={data_product} /></td>);
				if (k == 3) {
					row2++;
					k = 1;
				}
				else {
					k++;
				}
			}
			if (product.type == 'S') {
				if (l == 1) {
					DataS[row3] = [];
				}
				DataS[row3].push(<td className="EtiquetteAccessoiresSize" width="7cm" height="3cm"><EtiquetteAccessoires refEntered={product.reference} data={data_product} /></td>);
				if (l == 3) {
					row3++;
					l = 1;
				}
				else {
					l++;
				}
			}
			if (product.type == 'T') {
				if (m == 1) {
					DataT[row4] = [];
				}
				DataT[row4].push(<td className="EtiquetteAccessoiresSize" width="7cm" height="3cm"><EtiquetteAccessoires refEntered={product.reference} data={data_product} /></td>);
				if (m == 3) {
					row4++;
					m = 1;
				}
				else {
					m++;
				}
			}
			if (product.type == 'C') {
				DataC.push(<tr><td className="EtiquetteChaussuresSize"><EtiquetteChaussures refEntered={product.reference} data={data_product} /></td></tr>);
			}
			if (product.type == 'R') {
				if (j == 1) {
					DataR[row] = [];
				}
				console.log(DataR)
				DataR[row].push(<td className="EtiquetteRaquettesSize" width="11.5cm" height="4cm"><EtiquetteRaquettes refEntered={product.reference} data={data_product} /></td>);
				DataR[row].push(<td className="EtiquetteDemiSize" width="5.7cm" height="4cm"><DemiEtiquette refEntered={product.reference} data={data_product} /></td>);
				if (j == 1) {
					row++;
					j = 1;
				}
				else {
					j++;
				}
			}
		}
		console.log(DataR)
		return (
		<>
			<table>	
				<tbody>
					{DataA.map((dR) => (
						<tr>{dR}</tr>
					))}
				</tbody>
			</table>
			{(DataA.length>0) && (
				<div className="page-break"></div>
			)}
			<table>
				<tbody>
					{DataC}
				</tbody>
			</table>
			{(DataC.length>0) && (
				<div className="page-break"></div>
			)}
			<table>
				<tbody>
					{DataR.map((dR) => (
						<tr>{dR}</tr>
					))}
				</tbody>
			</table>
			{(DataR.length>0) && (
				<div className="page-break"></div>
			)}
			<table>	
				<tbody>
					{DataT.map((dR) => (
						<tr>{dR}</tr>
					))}
				</tbody>
			</table>
			{(DataT.length>0) && (
				<div className="page-break"></div>
			)}
			<table>	
				<tbody>
					{DataS.map((dR) => (
						<tr>{dR}</tr>
					))}
				</tbody>
			</table>
		</>
	   );
	};

	if (!isLoaded) return <LoadingScreen />;

	return (
		<>
			<Helmet>
		<title>Tickets du jour</title>
      </Helmet>
			<Navigation />
			<div className="container">
				<div className="row">
					<ReactToPrint
						trigger={() => <button className="btnDailyMailing no-print" type="button"><FaPrint className="svg-icons" /></button>}
						content={() => componentRef.current}
					/></div></div>


			<ComponentToPrint logo={logo} products={products} getEtiquette={getEtiquette} ref={componentRef} />
		</>
	);
}
