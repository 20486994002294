import React, { useContext, useState, useRef } from "react";
import { FaPrint, FaSearch, FaRedoAlt } from "react-icons/fa";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import ReactToPrint from 'react-to-print';
import { Helmet } from 'react-helmet';
import { useReactToPrint } from 'react-to-print';
import Navigation from "../components/Navigation";
import DemiEtiquette from "../components/DemiEtiquette";
import EtiquetteAccessoires from "../components/EtiquetteAccessoires";
import EtiquetteChaussures from "../components/EtiquetteChaussures";
import EtiquetteRaquettes from "../components/EtiquetteRaquettes";
import SearchBar from "../components/SearchBar";
import "../styles/Ticketing.css";
import "../styles/App.css";
import "../styles/Home.css";
import logo from "../assets/Logoplus.png";

export const ComponentToPrint = React.forwardRef((props, ref) => {
	const getPageMargins = () => {
	  return `@page { margin: 2cm 2cm 2cm 2cm !important; }`;
  };
	return (
	<><style>{getPageMargins()}</style>
		<div className="container-fluid no-margin-top-left no-padding-print" ref={ref}>
			<div className="row">
				{/* Affichage du visuel de l'étiquette */}
				<div className="etiquetteDailyMailing etiquetteDailyMailing"  >
					{props.products && props.getEtiquette(props.products)}
				</div>
			</div>
		</div></>
	);
});

export default function Ticketing() {
	const componentRef = useRef();
	const printRef = React.useRef();
	const [etiquetteToPrint, setEtiquetteToPrint] = useState();
	const [etiquette, setEtiquette] = useState();
	const [refEntered, setRefEntered] = useState([]);
	const [buttonVisualisation, setButtonVisualisation] = useState(false);
	const [data, setData] = useState([]);

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const handleClick = () => {
		setButtonVisualisation(!buttonVisualisation);
		document.getElementById("searchBar").setAttribute("disabled", true);
		if (buttonVisualisation) {
			setEtiquette(0);
			setRefEntered("");
			document.getElementById("searchBar").removeAttribute("disabled", false);
		}
	};

	const handleDownloadPdf = async () => {
		const element = printRef.current;
		const canvas = await html2canvas(element);
		const data = canvas.toDataURL('image/png');

		var pageData = canvas.toDataURL('image/jpeg', 1.0);
		let width = 210;
		let height = canvas.height;
		let widthRatio = width / canvas.width;
		let heightRatio = height / canvas.height;
		let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
		var pdf = new jsPDF('p', 'mm', [width, canvas.height * ratio]);
		pdf.addImage(pageData, 'JPEG', 0, 0, width, canvas.height * ratio);
		pdf.save('etiquettes.pdf')
	};

	/* const getEtiquette = (product) => {
		 let data_product = {};
		 data_product[product.reference] = product;
		 if(!etiquette && buttonVisualisation){
			 if(product.type == 'A'){
				 return (
				   <EtiquetteAccessoires refEntered={product.reference} data={data_product}/>
				);  
			 }
			 if(product.type == 'C'){
				 return (
				   <EtiquetteChaussures refEntered={product.reference} data={data_product}/>
				 );  
			}
			if(product.type == 'R'){
				return (
				   <>
					   <EtiquetteRaquettes refEntered={product.reference} data={data_product}/>
					   <DemiEtiquette refEntered={product.reference} data={data_product} />
				   </>
				);  
		   }
		 }
		 if(etiquette && buttonVisualisation){
			 switch (etiquette) {
				 case "1":
				   return <DemiEtiquette refEntered={product.reference} data={data_product}/>;
				 case "2":
				   return <EtiquetteAccessoires refEntered={product.reference} data={data_product}/>;
				 case "3":
				   return <EtiquetteChaussures  refEntered={product.reference} data={data_product} />;
				 case "4":
				   return <><EtiquetteRaquettes  refEntered={product.reference} data={data_product} /><DemiEtiquette refEntered={product.reference} data={data_product} /></>;
				 default:
				   return null;
			 }
		 }
		 
	 };*/

	const getEtiquette = (products) => {
		if (!etiquette && buttonVisualisation) {
			let DataA = [];
			let DataC = [];
			let DataR = [];
			let j = 1, k = 1;
			let row = 1, row2 = 1, row3 = 1;
			for (let i = 0; i < products.length; i++) {
				let product = products[i];
				let data_product = {};
				data_product[product.reference] = product;
				if (product.type == 'A') {
					if (k == 1) {
						DataA[row2] = [];
					}
					DataA[row2].push(<td className="EtiquetteAccessoiresSize" width="7cm" height="3cm"><EtiquetteAccessoires refEntered={product.reference} data={data_product} /></td>);
					if (k == 3) {
						row2++;
						k = 1;
					}
					else {
						k++;
					}
				}
				if (product.type == 'S') {
					if (l == 1) {
						DataS[row3] = [];
					}
					DataS[row3].push(<td className="EtiquetteAccessoiresSize" width="7cm" height="3cm"><EtiquetteAccessoires refEntered={product.reference} data={data_product} /></td>);
					if (l == 3) {
						row3++;
						l = 1;
					}
					else {
						l++;
					}
				}
				if (product.type == 'T') {
					if (m == 1) {
						DataT[row4] = [];
					}
					DataT[row4].push(<td className="EtiquetteAccessoiresSize" width="7cm" height="3cm"><EtiquetteAccessoires refEntered={product.reference} data={data_product} /></td>);
					if (m == 3) {
						row4++;
						m = 1;
					}
					else {
						m++;
					}
				}
				if (product.type == 'C') {
					DataC.push(<tr><td className="EtiquetteChaussuresSize"><EtiquetteChaussures refEntered={product.reference} data={data_product} /></td></tr>);
				}
				if (product.type == 'R') {
					if (j == 1) {
						DataR[row] = [];
					}
					console.log(DataR)
					DataR[row].push(<td className="EtiquetteRaquettesSize" width="11.5cm" height="4cm"><EtiquetteRaquettes refEntered={product.reference} data={data_product} /></td>);
					if (j == 1) {
						row++;
						j = 1;
					}
					else {
						j++;
					}
				}
			}
			console.log(DataR)
			return (
				<>
					<table>
						{DataA.map((dR) => (
							<tr>{dR}</tr>
						))}
					</table>
					{(DataA.length>0) && (
				<div className="page-break"></div>
			)}
					<table>
						{DataC}
					</table>
					{(DataC.length>0) && (
				<div className="page-break"></div>
			)}
					<table>
						{DataR.map((dR) => (
							<tr>{dR}</tr>
						))}
					</table>

				</>
			);
		}
		if (etiquette && buttonVisualisation) {
			let DataA = [];
			let DataC = [];
			let DataR = [];
			let DataD = [];
			let DataB = [];
			let DataT = [];
			let j = 1, k = 1, l = 1, m = 1, n = 1;
			let row = 1, row2 = 1, row3 = 1, row4 = 1, row5 = 1;
			for (let i = 0; i < products.length; i++) {
				let product = products[i];
				let data_product = {};
				data_product[product.reference] = product;
				if (etiquette == 1) {
					if (l == 1) {
						DataR[row3] = [];
					}
					console.log(DataR)
					DataR[row3].push(<td className="EtiquetteDemiSize" width="5.7cm" height="4cm"><DemiEtiquette refEntered={product.reference} data={data_product} /></td>);
					if (l == 3) {
						row3++;
						l = 1;
					}
					else {
						l++;
					}
				}
				if (etiquette == 2) {
					if (k == 1) {
						DataA[row2] = [];
					}
					DataA[row2].push(<td className="EtiquetteAccessoiresSize" width="7cm" height="3cm"><EtiquetteAccessoires refEntered={product.reference} data={data_product} /></td>);
					if (k == 3) {
						row2++;
						k = 1;
					}
					else {
						k++;
					}
				}
				if (etiquette == 3) {
					DataC.push(<tr><td className="EtiquetteChaussuresSize"><EtiquetteChaussures refEntered={product.reference} data={data_product} /></td></tr>);
				}
				if (etiquette == 4) {
					if (j == 1) {
						DataR[row] = [];
					}
					console.log(DataR)
					DataR[row].push(<td className="EtiquetteRaquettesSize" width="11.5cm" height="4cm"><EtiquetteRaquettes refEntered={product.reference} data={data_product} /></td>);
					if (j == 2) {
						row++;
						j = 1;
					}
					else {
						j++;
					}
				}
				if (etiquette == 5) {
					if (m == 1) {
						DataB[row4] = [];
					}
					DataB[row4].push(<td className="EtiquetteAccessoiresSize" width="7cm" height="3cm"><EtiquetteAccessoires refEntered={product.reference} data={data_product} /></td>);
					if (m == 3) {
						row4++;
						m = 1;
					}
					else {
						m++;
					}
				}
				if (etiquette == 6) {
					if (n == 1) {
						DataT[row5] = [];
					}
					DataT[row5].push(<td className="EtiquetteAccessoiresSize" width="7cm" height="3cm"><EtiquetteAccessoires refEntered={product.reference} data={data_product} /></td>);
					if (n == 3) {
						row5++;
						n = 1;
					}
					else {
						n++;
					}
				}
			}
			console.log(DataR)
			return (
				<>
					<table>
						{DataA.map((dR) => (
							<tr>{dR}</tr>
						))}
					</table>
					<table>
						{DataC}
					</table>
					<table>
						{DataR.map((dR) => (
							<tr>{dR}</tr>
						))}
					</table>
					<table>
						{DataB.map((dR) => (
							<tr>{dR}</tr>
						))}
					</table>
					<table>
						{DataT.map((dR) => (
							<tr>{dR}</tr>
						))}
					</table>
				</>
			);
		}
	};

	return (
		<>
		<Helmet>
		<title>Tickets Manuelles</title>
      </Helmet>
			<Navigation />
			<div className="container">

				<img className="Logo2bad-login no-print" src={logo} alt="Logo" />
				<div className="row">
					{/* Barre de sélection du format d'étiquette */}
					{/* Barre de recherche de références d'étiquette */}
					<SearchBar
						refEntered={refEntered}
						setRefEntered={setRefEntered}
						setButtonVisualisation={setButtonVisualisation}
						setData={setData}
						etiquette={etiquette}
						setEtiquette={setEtiquette}
					/>

					<div className="btnContainer no-print">
						{/* Affichage du bouton "Voir l'étiquette"/"Réinitialiser" */}
						{parseInt(etiquette, 10) !== 0 && refEntered?.length !== 0 && data[refEntered] ? (
							<button
								className="btnGenerationEtiquette"
								type="button"
								onClick={handleClick}
							>
								{buttonVisualisation ? (
									<FaRedoAlt className="svg-icons-home" />
								) : (
									<FaSearch className="svg-icons-home" />
								)}
							</button>
						) : null}

						{/* Affichage du bouton "Imprimer" */}
						{buttonVisualisation ? (
							<ReactToPrint
								trigger={() => <button className="btnGenerationEtiquette" type="button"><FaPrint className="svg-icons-home" /></button>}
								content={() => componentRef.current}
							/>
						) : null}
					</div>
					

				</div>
			</div>
			
			<ComponentToPrint products={data} getEtiquette={getEtiquette} ref={componentRef} />
	
		</>
	);
}
