/* eslint-disable no-alert */
import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import { getToken, setToken } from "../components/useToken";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  FaHome,
  FaStore,
  FaAngleLeft,
  FaAngleRight,
  FaCog,
  FaPrint,
  FaSearch,
  FaCalendarDay
} from "react-icons/fa";
import { GiExitDoor } from "react-icons/gi";
import { MdPrecisionManufacturing } from "react-icons/md";
import axios from "axios";
import swal from "sweetalert";
import logo from "../assets/Logoplus-removebg.png";
import "../styles/Navigation.css";

export default function Navigation() {
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [shop, setShop] = useState('loading');

  const width = menuCollapse ? "80px" : "300px";

  const navigate = useNavigate();

  const handleSubmit = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
    swal("Déconnexion réussie.", "A bientôt !", "success");
    navigate("/");
  };

  const menuIconClick = () =>
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);

  const token = getToken();
  
  useEffect(() => {
	axios.get(`${process.env.REACT_APP_BACKEND_URL}?action=findCurrentShop&ajax=1&token=${token.token}`).then((response) => {
		console.log('response2=', response);
      setShop(response.data);
    });
  }, []);
  
  if(shop == 'loading'){
	  return <LoadingScreen />
  }

  return (
    <div id="header" className="no-print" style={{ width, zIndex: "1000" }}>
      <ProSidebar>
        <SidebarHeader>
          <div className="logotext">
            {menuCollapse ? (
              <img className="Logo2bad-collapse" src={logo} alt="Logo" />
            ) : (
              <img className="Logo2bad" src={logo} alt="Logo" />
            )}
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <MenuItem icon={<FaHome className="svg-icons" />}>
              <Link to="/dashboard" />
              Accueil
            </MenuItem>
            <MenuItem icon={<FaSearch className="svg-icons" />}>
              <Link to={"/ticketing"} />
              Tickets Manuelles
            </MenuItem>
            <MenuItem
              icon={<FaPrint className="svg-icons"/>}
            >
			<Link to={`/tickets${shop.shop ? '/' + shop.shop : ''}`} />
              Tickets du jour
            </MenuItem>
			<MenuItem
              icon={<FaCalendarDay className="svg-icons"/>}
            >
			<Link to={`/ticketstoday${shop.shop ? '/' + shop.shop : ''}`} />
              Tickets magasin
            </MenuItem>
			{shop?.role === "ROLE_ADMIN" && (
				<>
					<MenuItem icon={<FaStore className="svg-icons" />}>
						<Link to="/magasins" />
						Gestion des magasins
					</MenuItem>
					<MenuItem icon={<MdPrecisionManufacturing className="svg-icons" />}>
						<Link to="/marques" />
						Gestion des marques
					</MenuItem>
				</>
			)}
            <MenuItem icon={<FaCog className="svg-icons" />}>
              <Link to="/settings" />
              Paramètres
            </MenuItem>
            <button type="button" className="closemenu" onClick={menuIconClick}>
              {menuCollapse ? (
                <FaAngleRight className="arrow" />
              ) : (
                <FaAngleLeft className="arrow" />
              )}
            </button>
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape="square">
            <MenuItem
              onClick={handleSubmit}
              icon={<GiExitDoor className="svg-icons" />}
            >
              Déconnexion
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
}
