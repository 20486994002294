import React, { useContext, useState, useEffect } from "react";
import ManuSwitch from "./ManuSwitch";
import PrixP2BBleu from "../assets/PrixP2BBleu.png";
import P2BLogo from "../assets/Logoplus-removebg2.png";
import PVC from "../assets/pvc.png";
import PrixPlusdDebadAD from '../assets/prixplusddebad_ad.png';
import PrixPlusdDebadADPromo from '../assets/prixplusddebad_ad_promo.png';
import Coupon from "../assets/coupon.webp";
import CheckGreen from "../assets/checkg.png";
import CheckRed from "../assets/checkr.png";
import chaussure1 from "../assets/chaussure1.png";
import accessoire2 from "../assets/accessoire2.png";
import "../styles/App.css";
import "../styles/EtiquetteAccessoires.css";

export default function EtiquetteAccessoires({ refEntered, data }) {
    const [nomProduit, setNomProduit] = useState("bigSizeEtiquetteAccessoires");
    return (
        <div className="fontEtiquette EtiquetteAccessoires">
            <div className="conteneurEtiquetteAccessoires">
                <div className="conteneurCaracteristiquesAccessoires">
                    <div className="accessoiresCheckGreen">
                        {/* importer logo du fabricant */}
                        {data[refEntered].BHA && (
                            <img src={CheckGreen} alt="pvc" />
                        )}
                    </div>
                    <div className="accessoiresBlockTop">
                        <ManuSwitch product={data[refEntered]} />
                    </div>
                    <div className="accessoiresBlockCenter">
                        <div className={nomProduit}>
                            {(data[refEntered].name.replace('(Non Cordée)', '').replace('(Cordée)', '').length > 15) ? data[refEntered].name.replace('(Non Cordée)', '').replace('(Cordée)', '') : data[refEntered].name.replace('(Non Cordée)', '').replace('(Cordée)', '')}
                        </div>
                    </div>
                    <div className="caracteristiquesProduitAccessoires">
                        {/* importer genre - pratique */}
                        {data[refEntered]?.features_string}
                    </div>
                </div>

                <div className="conteneurPrixAccessoires">
                    <div className="PVCAccessoires">
                        <img src={chaussure1} alt="LogoP2B" />
                        <div className="PVCAccessoiresPrice">
                            {/* importer Prix de Vente Conseillé avec mention préalable "PVC" */}
                            PVC<br /><span>{parseFloat(data[refEntered].PVC, 10).toFixed(2)} €</span>
                        </div>
                    </div>
                    <div className="logoP2BouPromoAccessoires">
                        {/* importer logo Plus2Bad ou logo Promo */}
                        {data[refEntered].is_promo && !data[refEntered].CC ? (
                            <div className="logoP2BouPromoAccessoiresPromo">PROMO</div>
                        ) : (
							<div className="logoP2BouPromoAccessoiresPrixDebad"><span>PRIX</span><img src={P2BLogo} /></div>
                        )}
                    </div>
                    <div className="PVAccessoires">
                        <img src={accessoire2} alt="LogoP2B" />
                        {/* importer Prix de Vente sans mention préalable "PV" */}
                        <div className="PVAccessoiresPrice">
                            {parseFloat(data[refEntered].PV, 10).toFixed(2)} €
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}