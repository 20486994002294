/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
import React, { useState } from "react";
import { redirect, useNavigate } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../styles/Login.css";
import logo from "../assets/Logoplus.png";
import { getToken, setToken } from "../components/useToken";
import swal from "sweetalert";

async function loginUser(credentials) {
  const data = { 'action': 'loginShop', 'shop': credentials.shop, 'password': credentials.password, 'ajax': 1 };
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url: `${process.env.REACT_APP_BACKEND_URL}`
  };
  let res = await axios(options);
  console.log('res=', res)
  return res.data;
}

export default function Login() {
  const navigate = useNavigate();
  const [shop, setShop] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await loginUser({
      shop,
      password,
    });
    console.log('token=', token);
    if (token.token != false) {
      setToken(token);
      navigate('/dashboard');
    }
    else {
      swal("Vérifiez votre nom d'utilisateur et votre mot de passe!.", '', "error");
    }
  };

  // fonction qui permet de désactiver le bouton <se connecter> si le champs ville est vide
  function validateForm() {
    return shop.length > 0;
  }

  if (getToken()?.token) {
    window.location.href = '/dashboard';
  }

  return (
    <>
	<Helmet>
		<title>Connexion</title>
      </Helmet>
      <div className="Logo">
        <img className="Logo2bad-login" src={logo} alt="Logo" />
      </div>
      <div className="Login">
        <Form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "column",
            alignItems: "center",
          }}
        >
          <Form.Group size="lg" controlId="shop">
            <Form.Label>Ville</Form.Label>
            <br />
            <Form.Control
              autoFocus
              type="shop"
              value={shop}
              onChange={(e) => setShop(e.target.value)}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="password">
            <Form.Label>Mot de passe</Form.Label>
            <br />
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <br />
          <Button
            block
            size="lg "
            type="submit"
            disabled={!validateForm()}
            style={{
              backgroundColor: "#3D3D3D",
              border: "none",
              width: "207px",
              color: "white",
            }}
          >
            Se connecter
          </Button>
        </Form>
      </div>
    </>
  );
}
