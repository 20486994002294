import P2BLogo from "../assets/Logoplus-removebg2.png";
import Yonex from "../assets/Manufacturers/3.png";
import FzForzaLogo from "../assets/Manufacturers/8.png";
import KarakalLogo from "../assets/Manufacturers/14.png";
import BabolatLogo from "../assets/Manufacturers/18.png";
import CarltonLogo from "../assets/Manufacturers/19.png";
import WilsonLogo from "../assets/Manufacturers/27.png";
import AdidasLogo from "../assets/Manufacturers/29.png";
import OliverLogo from "../assets/Manufacturers/32.png";
import DunlopLogo from "../assets/Manufacturers/33.png";
import SalmingLogo from "../assets/Manufacturers/37.png";
import TechnifibreLogo from "../assets/Manufacturers/40.png";
import QetzalLogo from "../assets/Manufacturers/47.png";
import VictorLogo from "../assets/Manufacturers/48.png";
import AsicsLogo from "../assets/Manufacturers/51.png";

function ManuSwitch({ product }) {
	console.log(product.img_manufacturer);
	if(product.img_manufacturer){
		return <img src={product.img_manufacturer} alt="Yonex" />;
	}
	else if(product.img_manufacturer_ps){
		return <img src={product.img_manufacturer_ps} alt="Yonex" />;
	}
	else{
		switch (parseInt(product.id_manufacturer)) {
			case 3:
				return <img src={Yonex} alt="Yonex" />;
			case 8:
				return <img src={FzForzaLogo} alt="FzForza" />;
			case 13:
				return <img src={P2BLogo} alt="Plus2Bad" />;
			case 14:
				return <img src={KarakalLogo} alt="Karakal" />;
			case 18:
				return <img src={BabolatLogo} alt="Babolat" />;
			case 19:
				return <img src={CarltonLogo} alt="Carlton" />;
			case 27:
				return <img src={WilsonLogo} alt="Wilson" />;
			case 29:
				return <img src={AdidasLogo} alt="Adidas" />;
			case 32:
				return <img src={OliverLogo} alt="Oliver" />;
			case 33:
				return <img src={DunlopLogo} alt="Dunlop" />;
			case 37:
				return <img src={SalmingLogo} alt="Salming" />;
			case 40:
				return <img src={TechnifibreLogo} alt="Technifibre" />;
			case 47:
				return <img src={QetzalLogo} alt="Qetzal" />;
			case 48:
				return <img src={VictorLogo} alt="Victor" />;
			case 51:
				return <img src={AsicsLogo} alt="Asics" />;
			default:
				let classTxt = product.manufacturer_name.length>8 ? 'dlpro-manufacturer-name dlpro-manufacturer-name-custom': 'dlpro-manufacturer-name';
				return <div className={classTxt}>{product.manufacturer_name}</div>;
		}
	}
}

export default ManuSwitch;
