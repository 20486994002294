import React, { useContext, useEffect, useRef, useState } from "react";
import { GrSearch, GrClose } from "react-icons/gr";
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import "../styles/SearchBar.css";
import "../styles/Selector.css";

export default function SearchBar({ refEntered, setRefEntered, setButtonVisualisation, setData, etiquette, setEtiquette }) {
  useEffect(() => {
		fetch(`${process.env.REACT_APP_BACKEND_URL}?action=findAllProducts&ajax=1&ref=${refEntered}`)
		  .then((res) => res.json())
		  .then((result) => {
			  console.log();
			  setData(result);
			  if(!result.length){
				  setButtonVisualisation(false);
			  }
			  else{
				  setButtonVisualisation(true);
			  }
		  });
  }, [refEntered]);
  
  const handleChangeEtiquette = (e) => {
    setEtiquette(e.target.value);
  };

  const clearInput = () => {
	setRefEntered([]);
    setButtonVisualisation(false);
    //document.getElementById("searchBar").removeAttribute("disabled", false);
  };

  return (
    <div className="search no-print">
	  <form className="formContainer no-print">
       {/* Barre de sélection du format d'étiquette */}
       <label htmlFor="etiquette">
        <select
          id="etiquette"
          name="etiquette"
          value={etiquette}
          onChange={handleChangeEtiquette}
          className="form-selector"
        >
          <option value="0">Choix du type d'étiquette</option>
		  <option value="1">Demi étiquette</option>
          <option value="2">Etiquette accessoire</option>
          <option value="3">Etiquette chaussure</option>
		  <option value="4">Etiquette raquette</option>
		  <option value="5">Etiquette bagagerie</option>
		  <option value="6">Etiquette textile</option>
        </select>
       </label>
       </form>
      {/* Barre de recherche de références d'étiquette + logo loupe ou reset */}
      <div className="searchInputs">
		<TagsInput value={refEntered} onChange={(value) => setRefEntered(value)} inputProps={{ placeholder: 'Rechercher une référence...'}}/>
			<div className="searchIcon">
				{refEntered?.length === 0 ? (
					<GrSearch />
				) : (
					<GrClose id="clearBtn" onClick={clearInput} />
				)}
			</div>
      </div>
    </div>
  );
}
