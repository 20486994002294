import React, { useState, useContext, useEffect } from "react";
import DataTable from 'react-data-table-component';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';
import axios from "axios";
import qs from 'qs';
import Navigation from "../components/Navigation";
import LoadingScreen from "../components/LoadingScreen";
import logo from "../assets/Logoplus.png";
import { getToken, setToken } from "../components/useToken";
import { FaMinus, FaPlus, FaPen, FaTimes } from "react-icons/fa";
import swal from "sweetalert";

export default function Marques() {
	const token = getToken();
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [id, setId] = useState(false);
	const [show, setShow] = useState(false);
	const [marqueList, setMarqueList] = useState('loading');

	const handleClose = () => setShow(false);
	const handleShow = (e, row) => {
		setShow(true);
		setId(row.id_manufacturer);
	};

	const deleteLogoMarque = (id) => {
		// eslint-disable-next-line no-restricted-globals
		axios.get(`${process.env.REACT_APP_BACKEND_URL}?action=deleteLogoMarque&ajax=1&id=${id}`).then(
			(response) => { });
		swal("Logo APP supprimé avec succès.", "", "success");

		refreshMarques();
	};

	const CustomButtonEdit = ({ row }) => {
		return (
			<Button variant="light" onClick={(e) => handleShow(e, row)}><FaPen /></Button>
		);
	};

	const CustomButtonDelete = ({ row, deleteLogoMarque }) => {
		return (
			<Button variant="danger" onClick={(e) => deleteLogoMarque(row.id_manufacturer)}><FaTimes /></Button>
		);
	};

	const CustomHtmlRow = ({ row }) => {
		if (row.img) {
			return <img src={row.img} width="100" />;
		}
		else {
			return "-";
		}
	};

	const CustomHtmlRowApp = ({ row }) => {
		if (row.img_app) {
			return <img src={row.img_app} width="100" />;
		}
		else {
			return "-";
		}
	};

	const [columns, setColumns] = useState([
		{
			selector: row => row.id_manufacturer,
			name: 'ID',
			width: '50px'
		},
		{
			name: 'Logo PRESTASHOP',
			cell: row => <CustomHtmlRow row={row} />,

		},
		{
			name: 'Logo APP',
			cell: row => <CustomHtmlRowApp row={row} />,

		},
		{
			name: 'Marque',
			selector: row => row.name
		},
		{
			name: 'Ajouter logo APP',
			cell: row => <CustomButtonEdit row={row} />,
			button: true,
			width: '150px'
		},
		{
			name: 'Supprimer logo APP',
			cell: row => <CustomButtonDelete row={row} deleteLogoMarque={deleteLogoMarque} />,
			button: true,
			width: '150px'
		},
	]);

	const customStyles = {
		headRow: {
			style: {
				border: 'none',
			},
		},
		headCells: {
			style: {
				color: '#202124',
				fontSize: '14px',
			},
		},
		rows: {
			highlightOnHoverStyle: {
				backgroundColor: 'rgb(230, 244, 244)',
				borderBottomColor: '#FFFFFF',
				borderRadius: '25px',
				outline: '1px solid #FFFFFF',
			},
		},
		pagination: {
			style: {
				border: 'none',
			},
		},
	};

	const refreshMarques = () => {
		axios.get(`${process.env.REACT_APP_BACKEND_URL}?action=getAllMarques&ajax=1`).then((response) => {
			console.log('response1=', response);
			setMarqueList(response.data);
		});
	};

	useEffect(() => {
		if (marqueList == 'loading') {
			refreshMarques();
		}
	}, [marqueList]);


	if (marqueList == 'loading') {
		return <LoadingScreen />
	}

	const createCustomToolBar = (props) => {
		return (
			<div style={{ margin: '15px' }}>
				{props.components.btnGroup}
				<div className='col-xs-8 col-sm-4 col-md-4 col-lg-2'>
					{props.components.searchPanel}
				</div>
			</div>
		);
	}

	const editMarque = (e) => {
		e.preventDefault();
		if (id) {
			var bodyFormData = new FormData();
			bodyFormData.append('action', 'updateLLogoMarque');
			bodyFormData.append('id', id);
			bodyFormData.append('logo', selectedFile);
			bodyFormData.append('ajax', 1);
			const options = {
				method: 'POST',
				headers: { 'content-type': 'multipart/form-data' },
				data: bodyFormData,
				url: `${process.env.REACT_APP_BACKEND_URL}`,
			};
			axios(options)
				.then((response) => {
					console.log('response=', response);
					if (response.data.status == true) {
						swal("L'enregistrement enregistré avec succès.", "", "success");
						setId("");
						setSelectedFile("");
						setIsFilePicked(false);
					}
					else {
						swal("L'enregistrement a échoué.", "", "error");
						setId("");
						setSelectedFile("");
						setIsFilePicked(false);
					}
					refreshMarques();
					return response.data;
				})
				.catch(() => {
					swal("L'enregistrement a échoué.", "", "error");
					setId("");
					setSelectedFile("");
					setIsFilePicked(false);
				});
		}
		handleClose();
	};

	const changeHandler = (event) => {
		console.log(event.target.files[0]);
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};


	return (
		<>
		<Helmet>
		<title>Gestion des marques</title>
      </Helmet>
			<Navigation />
			<div className="Logo">
				<img className="Logo2bad-login" src={logo} alt="Logo" />
			</div>
			<div className="container-fluid" style={{ paddingLeft: '90px' }}>
				<DataTable data={marqueList} columns={columns} highlightOnHover pointerOnHover />
			</div>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Modifier une marque</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Logo marque:</Form.Label>
							<Form.Control
								type="file"
								onChange={(e) => changeHandler(e)}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Fermer
					</Button>
					<Button variant="primary" onClick={(e) => editMarque(e)}>
						Enregistrer
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
